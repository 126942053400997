import { useApi } from '~/composables/useApi';

export const useFtGetCategoryPrice = () => {
  const { query } = useApi();

  const getCategoryPrice:any = async (category_id) =>{
    const myGraphqlQuery = `
      query getCategoryPrice($category_id: String!){
        getCategoryPrice(category_id: $category_id){
          min_price
          max_price
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery, {category_id: category_id});

    return {
      data,
    };
  }
  
  return {
    getCategoryPrice,
  }
}


export default useFtGetCategoryPrice;